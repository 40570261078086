.about-me {
  margin-top: 15px;
  background-color: rgb(0, 0, 0, 0.6);
  margin-left: 15vh;
  margin-right: 15vh;
}

.about-me-img {
  border-radius: 5%;
}

.about-me-text {
  padding-left: 15%;
  padding-right: 15%;
}

.about-me-title {
  font-family: "Permanent Marker";
}

.technology-highlight {
  color: var(--teritiary-color)
}

.about-me-skill-list{
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0.5em 0 0 0;
}

@media screen and (max-width: 850px) {
  .about-me {
    margin-top: 15px;
    background-color: rgb(0, 0, 0, 0.6);
    margin: 10px;
  }

  .about-me-text{
    padding-left: 5%;
    padding-right: 5%;
  }

  .about-me-skill-list{
    list-style-type: none;
  }
}