.footer {
  align-self: end;
}

.footer-link{
  width: 100%;
}

.footer-img {
  max-width: 64px;
  background-color: rgba(59, 187, 255, 0);
  border-radius: 5%;
  box-shadow: 10px 10px;
}

.footer-list {
  display: flex;
  list-style-type: none;
  width: 100%;
  justify-content: space-evenly;
  padding:0;
}

.footer-item {
  padding: .9em;
}

.footer-item:hover{
  opacity: .8;
}

@media (max-width: 768px) {
  .footer-list{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .footer-width{
    width: 100%
  }

}