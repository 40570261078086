:root{
  --primary-color: #2b2d42;
  --secondary-color: #8d99ae;
  --teritiary-color: #8B8804;
  --background-color: #1F1F1F;
}

*{
  color: var(--secondary-color);
  font-size: 1.01em;
  line-height: 1.6
}

html{
  height: 100%;
}

body{
  background-image: url("../src/assets/background/Blueprint.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
  background-color: #1F1F1F;
  background-attachment: fixed;
  font-family: "Indie Flower";
}

@media (max-width: 768px){
  body{
    background-size: auto;
  }
}

a{
  color: var(--teritiary-color)
}

a:hover{
  color:black
}

main{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 2 50%;
}

h1{
  color: var(--teritiary-color);
}

h2{
  font-family: "Permanent Marker";
  color: var(--teritiary-color);
}

p{
  font-family: "Helvetica"
}

#body{
  display: flex;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  text-align: center;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* MEDIA QUERY */
@media (max-width: 767px) {
  #body{
    flex-direction: column;
  }
}