.contact-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 80vh;
  justify-content: space-around;
  padding: 1em;
}

.contact-title {
  font-family: "Permanent Marker";
}

.contact-text-div {
  background-color: rgba(0, 0, 0, 0.6);
  width: auto;
}

.contact-link {
  font-size: 1.5em;
}

form {
  display: inline-flex;
  flex-direction: column;
  width: 50%;
}

.contact-submit {
  margin-top: 10px;
}
