.post-heading-description{
  background-color: rgb(0, 0, 0, 0.6);
}

.card {
  margin-top: 15px;
  opacity: 0.5;
  background-color: rgba(0, 0, 0, 0);
  border: 0px;
}

.card-title{
  font-family: "Permanent Marker"
}

.card:hover {
  opacity: 1;
}

.card-img-top {
  height: 200px;
}

.card-body {
  background-color: rgba(0, 0, 0, 50%);
  border-radius: 2%;
}

.my-projects-title{
  font-family: "Permanent Marker"
}

.project-link:hover{
  color: var(--secondary-color)
}