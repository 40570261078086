nav {
  position: sticky;
  top: 0;
  left: 0;
  flex: 1 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  height: fit-content;
  min-height: 100vh;
}
@media only screen and (max-width: 768px){
  nav{
    position: static;
  }
}

.nav {
  align-items: end;
  justify-content: space-between;
}

.nav-container {
  width: 50%;
  background-color: rgba(0, 170, 255, 0.1);
}

.nav-upper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-tabs {
  width: 100%;
  justify-content: space-between;
}

.nav-link {
  color: var(--teritiary-color);
  font-weight: 900;
  font-size: 1.3em;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
  margin-left: auto;
  margin-right: auto;
}

.nav-link.active, .nav-link:focus{
  background-color: rgba(0, 0, 0, 0.5);
  color: var(--secondary-color);
  font-weight: bolder;
}

.nav-link:hover {
  color: var(--secondary-color);
}

.nav-div {
  display: flex;
}

.nav-list {
  list-style-type: none;
  padding: 0;
}

.nav-upper {
  margin-top: 2em;
}

.myName {
  font-family: "Permanent Marker";
}

.myTitle {
  font-family: "Indie Flower";
  font-weight: bold;
}

.headshot {
  border-radius: 50%;
  width: 50%;
  aspect-ratio: 1/1;
  object-fit: cover;
  margin: 1em;
  border: .5em solid var(--teritiary-color);
  background-color: rgb(187, 187, 187);
}

/* media queries */

@media (max-width: 768px) {
  .nav-container {
    width: 100%;
  }

  .nav-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 10px;
    margin: 0;
  }

  .nav-tab {
    margin: none;
    width: 100%;
  }

  .nav-link {
    width: 100%;
    margin: 0;
  }
}

@media (max-width: 426px) {
  nav {
    position: relative;
    min-height: 0;
    height: auto;
  }
}

#menu {
  position: absolute;
  width: 300px;
  margin: -100px 0 0 -50px;
  padding: 50px;
  padding-top: 125px;
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu li {
  padding: 10px 0;
  font-size: 22px;
}

.nav-container input:checked ~ ul {
  transform: none;
}
